import { LitElement, html } from 'lit-element';
import { repeat } from 'lit-html/directives/repeat.js';
import { specialsReplace } from '../utils/html';

class HomeMain extends LitElement {

    constructor () {
        super();

        this.client = null;
        this.data = null;
        this.filters = [];
        this.featured = [];
        this.past = [];
        this.upcoming = [];
        this.featured = [];
        this.videoType = 'all';

        // Loading screen
        this.loading = true;
        this.error = null;

        this.pageAnchored = false;
        this.order = 'latest';
        this.checkClick = this.checkClick.bind( this );
        this.refreshAvailableVideos = this.refreshAvailableVideos.bind( this );
    }

    createRenderRoot () {
        /**
        * Render template without shadow DOM. Note that shadow DOM features like
        * encapsulated CSS and slots are unavailable.
        */
        return this;
    }

    static get properties() {
        return {
            client: { type: String }
        };
    }

    // On bootup, add listeners
    connectedCallback() {
        super.connectedCallback();
        this.loadEvents();

        // Add a listener that indicates something has changed that will affect the filters
        window.addEventListener( 'event_item_changed', ( { detail: event} ) => {
            // Update current data with the updated event item
            const ix = this.data.findIndex( e => e.event_id === event.event_id );
            if (ix>=0) {
                this.data[ix] = { ...event }
            }
            this.refreshAvailableVideos();
        });
    }

    disconnectedCallback() {
        window.removeEventListener( 'event_item_changed', this.refreshAvailableVideos );
    }

    reload() {
        window.location.reload();
    }

    refreshAvailableVideos( evt ) {
      this.setAvailableVideos();
    }

    setVideoType( event ) {
        this.videoType = event.target.value;
        this.setAvailableVideos();
    }

    setAvailableVideos() {
        this.featured = this.data.filter( ( event ) => event.featured );

        // Check which filters we have...
        const filtersApplied = this.filters.filter( ( item ) => item.selected );

        const upcomingData = this.data.filter( ( event ) => (event.epoch === 'future' || ( event.epoch === 'current' && event.type === 'live' ) ) && ( this.videoType !== 'my' || event.my_videos ) );
        this.upcoming = filtersApplied.length ? upcomingData.filter( ( item ) => filtersApplied.find( ( tag ) => item.tags && item.tags.includes( tag.id ) ) ) : upcomingData;

        const pastData = this.data.filter( ( event ) => (event.epoch === 'past' || ( event.epoch === 'current' && event.type === 'vod' ) ) && ( this.videoType !== 'my' || event.my_videos ) );
        this.past = filtersApplied.length ? pastData.filter( ( item ) => filtersApplied.find( ( tag ) => item.tags && item.tags.includes( tag.id ) ) ) : pastData;

        this.requestUpdate();
    }

    checkClick( event ) {
        let currentElement = event.srcElement;
        let found = false;

        // Find which dropdown is active
        let active = null;
        const filteringTags = document.getElementById( 'filtering-tags' );
        if ( filteringTags && filteringTags.hasAttribute( 'open' ) ) active = 'filtering-tags';

        const filteringSort = document.getElementById( 'filtering-sort' );
        if ( filteringSort && filteringSort.hasAttribute( 'open' ) ) active = 'filtering-sort';

        while ( currentElement ) {
            if ( active === currentElement.id ) {
                found = true;
                currentElement = null;
            } else {
                currentElement = currentElement.parentElement;
            }
        }

        if ( !found ) {
            const filteringTags = document.getElementById( 'filtering-tags' );
            if ( filteringTags ) filteringTags.removeAttribute( 'open' );
            const filteringSort = document.getElementById( 'filtering-sort' );
            if ( filteringSort ) filteringSort.removeAttribute( 'open' );
        }
    }

    onOpen( event ) {
        if ( event.newState === 'open' ) {
            document.addEventListener( 'click', this.checkClick );
        } else {
            document.removeEventListener( 'click', this.checkClick );
        }
    }

    getFilters() {
        this.filters = this.data.reduce( ( acc, next ) => {
            if ( next.tags ) {
                next.tags.forEach( ( tag ) => {
                    const match = acc.find( ( item ) => item.id === tag );
                    if ( !match ) {
                        acc.push( { id: tag, copy: next.copy[ tag ], selected: false } );
                    }
                } )
            }

            return acc;
        }, [] );
    }

    applyQSFilters() {
        const urlParams = new URLSearchParams(window.location.search);
        const filter = urlParams.get('filter');
        if (filter) {
            const filters = filter.split(',');
            this.filters.forEach( f => {
                if (filters.includes(f.id)) {
                    f.selected = true;
                }
            })
        }
    }

    updateFilter( event ) {
        const target = event.target;
        const index = this.filters.findIndex( ( tag ) => tag.id === target.name );
        if ( index > -1 ) {
            this.filters[ index ].selected = target.checked;
            this.setAvailableVideos();
        }
    }

    removeFilter( item ) {
        item.selected = false;
        this.setAvailableVideos();
    }

    clearAll() {
        this.filters.forEach( ( filter ) => filter.selected = false );
        this.setAvailableVideos();
    }

    updateSort( event ) {
        this.order = event.target.value;
        document.getElementById( 'filtering-sort' ).removeAttribute( 'open' );
        this.sortData();
        this.setAvailableVideos();
    }

    sortData() {
        this.data = this.data.sort( ( a, b ) => {
            if ( this.order === 'a-z' || this.order === 'z-a' ) {
                const first = a.copy?.title?.toLowerCase();
                const second = b.copy?.title?.toLowerCase();
                
                if ( first && second ) {
                    if ( this.order === 'a-z' ) {
                        return first.localeCompare( second );
                    }
                    if ( this.order === 'z-a' ) {
                        return second.localeCompare( first );
                    }
                }
            } else {
                const first = a.start_time?.toLowerCase();
                const second = b.start_time?.toLowerCase();
                
                if ( first && second ) {
                    if ( this.order === 'oldest' ) {
                        return first.localeCompare( second );
                    }
                    if ( this.order === 'latest' ) {
                        return second.localeCompare( first );
                    }
                }
            }

            return 1;
        } );
    }

    async loadEvents() {
        try {
            this.loading = true;
            const response = await fetch( `${ window.constants.eventApi }/event?userid=${ window.constants.userId }`, {
                headers: {
                    'Authorization': `Bearer ${ window.constants.eventApiToken }`
                },
                credentials: 'include'
            });
            this.data = await response.json();
            this.getFilters();
            this.applyQSFilters();
            this.sortData();
            this.setAvailableVideos();
            this.loading = false;
        } catch ( err ) {
            console.log( 'Error loading data', err );
            this.error = err;
            this.loading = false;
            this.requestUpdate();
        }
    }

    updated(props) {

        if (!this.pageAnchored && !this.loading) {
         
            var hash = window.location.hash;
            if (hash) {
                var elem = document.querySelector( hash );
                if (elem) {
                    setTimeout( () => elem.scrollIntoView(), 0 );
                }
            }
            this.pageAnchored = true;
        }
    }

    render() {
        if ( this.error ) return html`
            <div class="HomeMain">
                <div class="primary">
                    <div class="content">
                        <h1 class="headline-xxl error-title">${ window.copy.getClientInstance( 'home_error_title' ) }</h1>
                        <p class="body-m error-description">${ window.copy.getClientInstance( 'home_error_description' ) }</p>
                        <button class="button error-button" type="button" @click=${ this.reload }>${ window.copy.getClientInstance( 'home_error_button' ) }</button>
                    </div>
                </div>
            </div>
        `;

        return html`
            <div class="HomeMain">
                ${ this.loading ? html`
                    <div class="loader">
                        <div class="loading-icon">
                            <img class="loading-image" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/loader/loader.svg" />
                        <div>
                    </div>
                ` : html`
                    <div class="primary">
                        <div class="content">
                            <h1 class="headline-xxl home-main-title">${ specialsReplace( window.constants.userId ? window.copy.getClientInstance( 'home_welcome_logged_in' ).replace( '$USER', window.constants.userFirstName ) : window.copy.getClientInstance( 'index_welcome_tagline' ) ) }</h1>
                            <p class="body-m home-main-description">${ specialsReplace( window.copy.getClientInstance( 'home_welcome_description' ) )}</p>
                            <!-- FEATURED EVENTS -->
                            ${ this.featured.length ? html`
                                <home-featured .events="${ this.featured }"></home-featured>
                            ` : `` }
                            <home-banner></home-banner>
                        </div>
                    </div>
                    <div class="content bottom">
                            ${ window.constants.userId ? html`
                                <h2 class="videos-title headline-xl">${ window.copy.getClientInstance( 'home_view_videos' ) }</h2>
                                <div class="selection">
                                    <div class="selection-item">
                                        <input id="all-videos" class="selection-checkbox" type="radio" name="videos" value="all" @change=${ this.setVideoType } ?checked=${ this.videoType === 'all' } />
                                        <label for="all-videos" class="selection-label body-l">${ window.copy.getClientInstance( 'home_view_videos_all' ) }</label>
                                    </div>
                                    <div class="selection-item">
                                        <input id="my-videos" class="selection-checkbox" type="radio" name="videos" value="my" @change=${ this.setVideoType } ?checked=${ this.videoType === 'my' } />
                                        <label for="my-videos" class="selection-label body-l">${ specialsReplace( window.copy.getClientInstance( 'home_view_videos_my' ) ) }</label>
                                    </div>
                                </div>

                                ${ this.videoType === 'my' ? html`<p class="videos-description body-m">${ specialsReplace( window.copy.getClientInstance( 'home_view_videos_description' ) ) }</p>` : '' }
                            ` : '' }
                    
                            <div class="filtering">
                                ${ this.filters.length ? html`
                                    <p class="filtering-label body-s">${ window.copy.getClientInstance( 'home_filtering' ) }</p>
                                    <details class="filtering-details" id="filtering-tags" @toggle=${ this.onOpen }>
                                        <summary class="filtering-summary body-s">
                                            ${ window.copy.getClientInstance( 'home_filtering_category' ) }
                                            <img class="icon" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/arrows/arrow-down-${ window.constants.client === 'ford' ? 'grey' : 'black' }.svg" />
                                        </summary>
                                        <ul class="list">
                                            ${ repeat( this.filters, ( item ) => html`
                                                <li class="list-item">
                                                    <input id="${ `filter-${ item.id }` }" class="list-checkbox" type="checkbox" .name=${ item.id } @change="${ this.updateFilter }" .checked=${ item.selected } />
                                                    <label for="${ `filter-${ item.id }` }" class="list-label body-s">${ item.copy }</label>
                                                </li>
                                            ` ) }
                                        </ul>
                                    </details>
                                ` : '' }

                                <p class="filtering-label body-s">${ window.copy.getClientInstance( 'home_sort_by' ) }</p>
                                <details class="filtering-details" id="filtering-sort" @toggle=${ this.onOpen }>
                                    <summary class="filtering-summary body-s">
                                        ${ this.order === 'a-z' ? window.copy.getClientInstance( 'home_sort_a_z' ) : null }
                                        ${ this.order === 'z-a' ? window.copy.getClientInstance( 'home_sort_z_a' ) : null }
                                        ${ this.order === 'latest' ? window.copy.getClientInstance( 'home_sort_latest' ) : null }
                                        ${ this.order === 'oldest' ? window.copy.getClientInstance( 'home_sort_oldest' ) : null }
                                        <img class="icon" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/arrows/arrow-down-${ window.constants.client === 'ford' ? 'grey' : 'black' }.svg" />
                                    </summary>
                                    <ul class="list">
                                        <li class="list-item">
                                            <input id="sort-a-z" class="list-checkbox" type="radio" .name="order" @change="${ this.updateSort }" .checked=${ this.order === 'a-z' } value='a-z' />
                                            <label for="sort-a-z" class="list-label body-s">${ window.copy.getClientInstance( 'home_sort_a_z' ) }</label>
                                        </li>
                                        <li class="list-item">
                                            <input id="sort-z-a" class="list-checkbox" type="radio" .name="order" @change="${ this.updateSort }" .checked=${ this.order === 'z-a' } value='z-a' />
                                            <label for="sort-z-a" class="list-label body-s">${ window.copy.getClientInstance( 'home_sort_z_a' ) }</label>
                                        </li>
                                        <li class="list-item">
                                            <input id="sort-latest" class="list-checkbox" type="radio" .name="order" @change="${ this.updateSort }" .checked=${ this.order === 'latest' } value='latest' />
                                            <label for="sort-latest" class="list-label body-s">${ window.copy.getClientInstance( 'home_sort_latest' ) }</label>
                                        </li>
                                        <li class="list-item">
                                            <input id="sort-oldest" class="list-checkbox" type="radio" .name="order" @change="${ this.updateSort }" .checked=${ this.order === 'oldest' } value='oldest' />
                                            <label for="sort-oldest" class="list-label body-s">${ window.copy.getClientInstance( 'home_sort_oldest' ) }</label>
                                        </li>
                                    </ul>
                                </details>
                            </div>

                            <div class="toggles">
                                ${ this.filters.length ? html`
                                    ${ repeat( this.filters, ( item ) => item.selected ? html`
                                        <div class="tag">
                                            <p class="label body-s">${ item.copy }</p>
                                            <button type="button" class="remove" @click=${ () => this.removeFilter( item ) }>
                                                <img class="remove-icon" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/schedule/icon-close.svg" />
                                            </button>
                                        </div>
                                    ` : '' ) }
                                    ${ this.filters.find( ( filter ) => filter.selected ) ? html`<button type="button" class="button tertiary clear-all" @click=${ this.clearAll }>${ window.copy.getClientInstance( 'home_clear_all' ) }</button>` : '' }
                                ` : '' }
                            </div>

                    
                            ${ this.upcoming.length ? html`
                                <home-collection id="upcoming" .featured=${ this.videoType == 'my' } .events="${ this.upcoming }" .itemsPerPage=${ this.videoType === 'all' ? 6 : 3 } .grid=${ this.videoType === 'all' } title="${ window.copy.getClientInstance( 'home_upcoming' ) }"></home-collection>
                            ` : '' }

                            ${ this.past.length ? html`
                                <home-collection id="on-demand" .events="${ this.past }" itemsPerPage="6" .grid=${ true } title="${ window.copy.getClientInstance( 'home_on_demand' ) }"></home-collection>
                            ` : '' }

                            ${ !this.upcoming.length && !this.past.length ? html`<p class="body-m">${ window.copy.getClientInstance( 'home_no_videos' ) }</p>` : '' }
                    </div>
                ` }
            </div>
        `;
    }
}

customElements.define( 'home-main', HomeMain );