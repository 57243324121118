const trademarkToHTML = (text) => {
  return text.replace( /\u2122/g, `<sup class="tm">TM</sup>`);
}

const registeredToHTML = (text) => {
  return text.replace( /\u00ae/g, `<sup class="reg">&reg;</sup>`);
}

export const specialsToHTML = (text) => {
  return trademarkToHTML( registeredToHTML ( text ?? '' ) );
}

export const urlReplace = (input) => {
  const re = /\b((https?):\/\/[-\w+&@#/%?=~_|!:,.;]*[-\w+&@#/%=~_|])/gi;
  let match, matches = [], txt = input || '';

  while ((match = re.exec(txt)) !== null) {
      matches.push(match)
  }

  matches.reverse().forEach( match => {
      const st = txt.substring(0, match.index);
      const en = txt.substring( match.index + match[0].length, 99999 );
      txt = st + `<a href="${match[0]}" target="_blank">${ specialsToHTML( match[0] ) }</a>` + en;
  });
  
  return document.createRange().createContextualFragment(txt);
}

export const specialsReplace = (text) => {
  return (text || '').trim() ? document.createRange().createContextualFragment( specialsToHTML(text) ) : '';
}
